<template>
  <section>
    <!--工具条-->
    <el-form :inline="true" class="toolbar" :model="searchForm">
      <el-form-item label="账户名称">
        <el-input v-model="searchForm.name" size="mini" clearable></el-input>
      </el-form-item>
      <el-form-item label="账号">
        <el-input v-model="searchForm.accountNo" size="mini" clearable></el-input>
      </el-form-item>
      <el-form-item label="渠道">
        <el-select v-model="searchForm.payChannel" size="mini" clearable>
          <el-option
            v-for="item in payChannelList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="search" type="primary" size="mini">查询</el-button>
        <el-button @click="add" type="primary" size="mini">添加</el-button>
      </el-form-item>
    </el-form>

    <el-table :data="tableData" border height="650" style="width: 100%" v-loading="loading">
      <el-table-column prop="no" label="序号" width="50" type="index"></el-table-column>
      <el-table-column prop="name" label="账户名称"></el-table-column>
      <el-table-column prop="accountNo" label="账号"></el-table-column>
      <el-table-column prop="accountKey" label="Key"></el-table-column>
      <el-table-column prop="accountSecret" label="Secret"></el-table-column>
      <el-table-column prop="payChannel" label="渠道"></el-table-column>
      <el-table-column prop="createTime" label="创建时间">
        <template slot-scope="scope">
          <span>{{dateTransform(scope.row.createTime)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="updateTime" label="修改时间">
        <template slot-scope="scope">
          <span>{{dateTransform(scope.row.updateTime)}}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="edit(scope.row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pager"
      @size-change="pageSizeChange"
      @current-change="pageIndexChange"
      :current-page="pageIndex"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="dataTotal"
    ></el-pagination>
    <el-dialog
      title="添加还款账号"
      width="50%"
      :close-on-click-modal="false"
      :visible.sync="addDialogForm"
    >
      <el-form :model="addForm" :rules="rules" ref="addForm" label-width="100px">
        <el-form-item label="账号名称" prop="name">
          <el-col :span="12">
            <el-input v-model="addForm.name" clearable></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="账号" prop="accountNo">
          <el-col :span="12">
            <el-input v-model="addForm.accountNo" clearable></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="Key" prop="accountKey">
          <el-col :span="12">
            <el-input v-model="addForm.accountKey" clearable></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="Secret" prop="accountSecret">
          <el-col :span="12">
            <el-input v-model="addForm.accountSecret" clearable></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="支付渠道" prop="payChannel">
          <el-col :span="12">
            <el-select v-model="addForm.payChannel" clearable>
              <el-option
                v-for="item in payChannelList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-col>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="addDialogForm = false">取消</el-button>
        <el-button type="primary" @click="addSubmit" :loading="addLoading">确定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="修改还款账号"
      width="50%"
      :close-on-click-modal="false"
      :visible.sync="editDialogForm"
    >
      <el-form :model="editForm" :rules="rules" ref="editForm" label-width="100px">
        <el-form-item label="账号名称" prop="name">
          <el-col :span="12">
            <el-input v-model="editForm.name" clearable></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="账号" prop="accountNo">
          <el-col :span="12">
            <el-input v-model="editForm.accountNo" clearable></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="Key" prop="accountKey">
          <el-col :span="12">
            <el-input v-model="editForm.accountKey" clearable></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="Secret" prop="accountSecret">
          <el-col :span="12">
            <el-input v-model="editForm.accountSecret" clearable></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="支付渠道" prop="payChannel">
          <el-col :span="12">
            <el-select v-model="editForm.payChannel" clearable>
              <el-option
                v-for="item in payChannelList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-col>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="editDialogForm = false">取消</el-button>
        <el-button type="primary" @click="editSubmit" :loading="editLoading">确定</el-button>
      </div>
    </el-dialog>
  </section>
</template>

<script>
import api from "../../api/routerConfig/accountConfig";
import DataUtil from "../../utils/config/dataUtil";
import Enum from "../../utils/Enum/Enum";
export default {
  data() {
    return {
      pageIndex: 1,
      pageSize: 10,
      dataTotal: 0,
      payChannelList: Enum.payChannelList,
      loading: false,
      editDialogForm: false,
      editLoading: false,
      addLoading: false,
      addDialogForm: false,
      tableData: [],
      searchForm: {
        name: "",
        accountNo: "",
        payChannel: "",
        type: 1,
      },

      rules: {
        name: [
          {
            required: true,
            message: "请填写账号名称",
            trigger: "blue",
          },
        ],
        accountNo: [
          {
            required: true,
            message: "请填写账号",
            trigger: "blue",
          },
        ],
        accountKey: [
          {
            required: true,
            message: "请填写Key",
            trigger: "blue",
          },
        ],
        accountSecret: [
          {
            required: true,
            message: "请填写Secret",
            trigger: "blue",
          },
        ],
        payChannel: [
          {
            required: true,
            message: "请选择支付渠道",
            trigger: "blue",
          },
        ],
      },
      addForm: {
        name: "",
        accountNo: "",
        accountKey: "",
        accountSecret: "",
        payChannel: "",
      },
      editForm: {
        id: "",
        name: "",
        accountNo: "",
        accountKey: "",
        accountSecret: "",
        payChannel: "",
      },
    };
  },
  methods: {
    dateTransform(date) {
      return DataUtil.dateTransform(date);
    },
    search() {
      this.pageIndex = 1;
      this.bindGrid();
    },
    bindGrid() {
      this.loading = true;
      let _data = {};
      let content = Object.assign({}, this.searchForm);
      content.pageNo = this.pageIndex;
      content.pageSize = this.pageSize;
      _data.content = content;
      api.payAccountList(_data).then((res) => {
        this.logining = false;
        if (res.code == "0000") {
          this.loading = false;
          this.tableData = res.data.rows;
          this.dataTotal = res.data.total;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    add() {
      this.$refs.addForm && this.$refs.addForm.resetFields();
      this.addDialogForm = true;
    },
    addSubmit() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.addLoading = true;
          let _data = {};
          let content = Object.assign({}, this.addForm);
          (content.type = 1), (_data.content = content);
          api.addPayAccount(_data).then((res) => {
            this.addLoading = false;
            if (res.code == "0000") {
              this.addDialogForm = false;
              this.$message({
                message: "添加成功",
                type: "success",
              });
              this.bindGrid();
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    edit(row) {
      this.editForm.id = row.id;
      this.editForm.name = row.name;
      this.editForm.accountNo = row.accountNo;
      this.editForm.accountKey = row.accountKey;
      this.editForm.accountSecret = row.accountSecret;
      this.editForm.payChannel = row.payChannel;
      this.editDialogForm = true;
    },
    editSubmit() {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          this.editLoading = true;
          let _data = {};
          let content = Object.assign({}, this.editForm);
          (content.type = 1), (_data.content = content);
          api.editPayAccount(_data).then((res) => {
            this.editLoading = false;
            if (res.code == "0000") {
              this.editDialogForm = false;
              this.$message({
                message: "修改成功",
                type: "success",
              });
              this.bindGrid();
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },

    // 分页
    pageSizeChange(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.bindGrid();
    },
    pageIndexChange(val) {
      this.pageIndex = val;
      this.bindGrid();
    },
  },
  mounted: function() {
    this.bindGrid();
  },
  components: {},
};
</script>

<style lang="scss">
@import "../../assets/css/base";
</style>
