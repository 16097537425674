import request from "@/utils/http/requset";

function payAccountList(data) {
  return request({
    url: "/manage/payAccount/search",
    method: "post",
    ContentType: "application/json;charset=UTF-8",
    data: data
  });
}
function addPayAccount(data) {
  return request({
    url: "/manage/payAccount/create",
    method: "post",
    ContentType: "application/json;charset=UTF-8",
    data: data
  });
}
function editPayAccount(data) {
  return request({
    url: "/manage/payAccount/update",
    method: "post",
    ContentType: "application/json;charset=UTF-8",
    data: data
  });
}
export default {
  payAccountList,
  addPayAccount,
  editPayAccount
};
